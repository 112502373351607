import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/available',
    name: 'AvailableSpace',
    component: () => import('../views/spaces/AvailableSpace.vue'),
  },
  {
    path: '/available/:spaceId/details',
    name: 'SpaceDetails',
    component: () => import('../views/spaces/SpaceDetails.vue'),
    props: true
  },
  {
    path: '/location',
    name: 'Location',
    component: () => import('../views/Location.vue'),
  },
  {
    path: '/about',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
