<template>
  <header>
    <nav class="navbar fixed-top navbar-expand-md navbar-light bg-white">
      <div v-if="building" class="container-fluid">
        <router-link class="navbar-brand" :to="{ name: 'Home' }"
          ><img
            v-if="building"
            id="navbar-logo"
            :src="building.logoURLThumb"
            class="small"
        /></router-link>
        <router-link class="nav-text" :to="{ name: 'Home' }">
          <h3>{{ buildingNameTitle }}</h3>
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav ms-auto">
            <!-- <li>
                <a class="nav-link" :href="tenantURL" style="margin-bottom: 5px"
                  >Tenants</a
                >
              </li> -->
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('AvailableSpace')"
                >Available Space</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Location')"
                >Location</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('AboutUs')"
                >About Us</span
              >
            </li>
            <li class="nav-item">
              <span
                class="nav-link btn"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                @click="closeMenuRoute('Contact')"
                >Contact</span
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { computed } from "@vue/reactivity";
import getBuildingByURL from "@/composables/getBuildingByURL";

export default {
  setup() {
    const router = useRouter();
    const tenantURL = ref(process.env.VUE_APP_TENANT_URL);
    const managerURL = ref(process.env.VUE_APP_MANAGER_URL);
    const homePage = ref(process.env.VUE_APP_HOME_URL);
    const { building } = getBuildingByURL(window.location.host);

    const buildingNameTitle = computed(() => {
      console.log("NavBar computing");
      if (building.value) {
        document.title = building.value.name;
        const favicon = document.getElementById("favicon");
        if (building.value.faviconURL) {
          favicon.href = building.value.faviconURL;
        }
      }
      return building.value ? building.value.name : "Building";
    });
    // const handleScroll = () => {
    //   isScrolled.value =
    //     document.body.scrollTop > 50 || document.documentElement.scrollTop > 50;
    // };

    // onMounted(() => {
    //   window.addEventListener("scroll", handleScroll);
    // });

    // onUnmounted(() => {
    //   window.removeEventListener("scroll", handleScroll);
    // });

    const closeMenuRoute = routeName => {
      router.push({
        name: routeName,
        params: { buildingId: building.value.id }
      });
    };

    return {
      tenantURL,
      managerURL,
      homePage,
      building,
      buildingNameTitle,
      closeMenuRoute
    };
  }
};
</script>
<style>
/* HEADER / TOP MENU
------------------------------------------------- */

#navbar-logo {
  height: 80px;
  transition-property: height;
  transition-duration: 0.2s;
}

#navbar-logo.small {
  height: 40px;
}

li.nav-item {
  text-align: center;
  margin-right: 5px;
}

.navbar-expand-md .navbar-nav .nav-link {
  margin-top: 6px;
  color: #404042;
  font-weight: 600;
}
</style>