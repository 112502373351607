import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

//Bootstrap
import 'bootstrap/scss/bootstrap.scss';
import { Modal } from "bootstrap";
import './assets/css/bootstrap-icons.css';

//global styles
import './assets/css/main.css';

createApp(App).use(router, Modal).mount('#app');
