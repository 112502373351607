import { ref } from 'vue';
import { projectFirestore } from '../firebase/config';
import { collection, where, query, getDocs, orderBy } from '@firebase/firestore';


const getBuildingByURL = (url) => {
    const building = ref(null);
    const spaces = ref(null);
    const errorBuilding = ref(null);

    const buildingsRef = collection(projectFirestore, 'buildings');
    const spacesRef = collection(projectFirestore, 'spaces');
    const q = query(buildingsRef, where("url", "==", url));
    // Static Query
    getDocs(q).then((querySnapshot) => {
        if (!querySnapshot.empty) {
            building.value = ({
                ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id
            });
            console.log(`Building Found: ${building.value.name}`);
            //TODO call spaces to get any available spaces
            const qSpace = query(spacesRef, where("available", "==", true), where("buildingId", "==", building.value.id), orderBy("order"));
            getDocs(qSpace).then((querySnapshotSpaces) => {
                if (!querySnapshotSpaces.empty) {
                    const results = [];
                    querySnapshotSpaces.forEach((doc) => {
                        results.push({ ...doc.data(), id: doc.id });
                    });
                    spaces.value = results;
                }
            }, err => {
                errorBuilding.value = `Spaces error, ${err.message}`;
            });

        } else {
            errorBuilding.value = "Building Not Found";
        }

    }, err => {
        errorBuilding.value = `Building error, ${err.message}`;
    });





    // Listen for changes
    // const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //     if (!querySnapshot.empty) {
    //         building.value = ({
    //             ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id
    //         });
    //     }
    // }, err => {
    //     console.log(err.message);
    //     errorBuilding.value = 'could not fetch the document';
    // });

    // watchEffect((onInvalidate) => {
    //     onInvalidate(() => unsubscribe());
    // });
    return { errorBuilding, building, spaces };

};

export default getBuildingByURL;